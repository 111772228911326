<template>
	<div>
		<loan-form :loanInfo.sync="loanInfo" @setDataChange="setDataChange"></loan-form>
		<a-descriptions bordered :title="$t('table.applyInfo.vehicleLabel.logFile')" size="small">
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.logFile')">
				<UploadPreviewFile :uploadFilePath.sync="logbook.logbookFile" :folderPath="$route.query.id"></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered :title="$t('table.applyInfo.vehicleLabel.logFileInfo')" size="small">
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicle')">
				<a-auto-complete v-model="logbook.make" :data-source="vehicleMakeData" placeholder="Vehicle Make" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.model')">
				<a-auto-complete v-model="logbook.model" :data-source="vehicleModelData" placeholder="Vehicle Model" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.year')">
				<a-input v-model="logbook.year" placeholder="Vehicle Years" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
				<a-input v-model="logbook.registrationNumber" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.frame')">
				<a-input v-model="logbook.frameNumber" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engineNo')">
				<a-input v-model="logbook.engineNo" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.attribution')" :span="3">
				<div class="box">
					<div class="list" v-for="(item, idx) in logbook.belongs" :key="idx">
						<div class="span_labe">
							PIN:
							<a-input v-model="logbook.belongs[idx].pin" />
						</div>
						<div class="span_labe">
							Name:
							<a-input v-model="logbook.belongs[idx].name" />
						</div>
						<a-button type="primary" @click="DelBelongs(idx)">{{ $t('page.delete') }}</a-button>
					</div>
					<div class="add-btn">
						<a-button type="primary" @click="AddBelongs" :disabled="logbook.belongs.length >= 2">
							{{ $t('page.add') }}
						</a-button>
					</div>
				</div>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicleUse')">
				<a-auto-complete v-model="logbook.vehicleUse" :data-source="$enumMap.vehicleUseEnum" placeholder="Vehicle Use" />
			</a-descriptions-item>
		</a-descriptions>
		<template v-if="loanInfo.loanType === 6">
			<a-descriptions bordered :title="$t('table.applyInfo.vehicleLabel.estimateBookFile')" size="small">
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.estimateBookFile')">
					<UploadPreviewFile :uploadFilePath.sync="valuation.file" :folderPath="$route.query.id" :callbackFun="getValuation">
						<template v-slot:btn>
							<a-button @click="estimateBookFileTxtBool = !estimateBookFileTxtBool" style="margin-left: 10px">
								{{ $t('table.applyInfo.personalLabel.fillManual') }}
							</a-button>
						</template>
					</UploadPreviewFile>
				</a-descriptions-item>
			</a-descriptions>
			<a-descriptions v-show="estimateBookFileTxtBool" bordered :title="$t('table.applyInfo.vehicleLabel.estimateBookFileTxt')" size="small">
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicle')">
					<a-auto-complete v-model="valuation.make" :data-source="vehicleMakeData" placeholder="Vehicle Make" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.model')">
					<a-auto-complete v-model="valuation.model" :data-source="vehicleValuationModelData" placeholder="Vehicle Model" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.year')">
					<a-input v-model="valuation.year" placeholder="Vehicle Years" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
					<a-input v-model="valuation.registrationNumber" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.frame')">
					<a-input v-model="valuation.frameNumber" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engineNo')">
					<a-input v-model="valuation.engineNo" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.estimate')">
					<a-input-number v-model="valuation.valuation" :precision="0" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.endValue')">
					<a-input-number v-model="valuation.saleValuation" :precision="0" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.createTime')">
					<a-date-picker :valueFormat="dateFormat" showTime type="date" v-model="valuation.reportTime" />
				</a-descriptions-item>
			</a-descriptions>
		</template>
		<a-descriptions bordered :title="$t('table.applyInfo.vehicleLabel.insurance')" size="small">
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.allInsurance')">
				<a-radio-group v-model="isAllRisks">
					<a-radio :value="1">
						{{ $t('page.yes') }}
					</a-radio>
					<a-radio :value="0">
						{{ $t('page.no') }}
					</a-radio>
				</a-radio-group>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.expTimeRe')" :span="2">
				<a-date-picker :valueFormat="dateFormat" showTime type="date" v-model="expireTime" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.stickersPic')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="insurancePic" :folderPath="$route.query.id" :isImgFile="true"> </UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.financialLabel.flow')" size="small">
			<a-descriptions-item :span="3">
				<a-table
					style="font-size: 12px"
					:columns="columns"
					size="small"
					:rowKey="(record, index) => index"
					:dataSource="transcations"
					:pagination="false"
					bordered
				>
					<div slot="file" slot-scope="text, obj">
						<a @click="openUrl(obj)">{{ obj.file }}</a>
					</div>
					<span slot="action" slot-scope="text, obj, index">
						<a-button @click="delFile(index)">{{ $t('table.useraccount.file.cloums.del') }}</a-button>
					</span>
				</a-table>
			</a-descriptions-item>
			<a-descriptions-item>
				<a-button type="primary" @click="ShowModal">
					<a-icon type="upload"></a-icon>
					{{ $t('table.applyInfo.personalLabel.uploadFile') }}
				</a-button>
				<a-button type="primary" @click="CheckLoanDetail" style="margin-left: 20px" :disabled="transcations.length == 0">
					{{ $t('page.checkReview') }}
				</a-button>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered title="Loan Application" size="small">
			<a-descriptions-item label="Loan Application Form">
				<UploadPreviewFile :uploadFilePath.sync="applicationFormFile" :folderPath="$route.query.id"> </UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.applicationLabel.supDoc')" size="small">
			<a-descriptions-item :span="3">
				<a-table :columns="supCloums" size="small" :rowKey="(record, index) => index" :dataSource="supplementFiles" :pagination="false" bordered>
					<span slot="fileName" slot-scope="text, obj">
						<a-input v-model="obj.filename" class="input_table" />
					</span>
					<span slot="file" slot-scope="obj">
						<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">
							{{ !$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : '' }}
						</a>
					</span>
					<span slot="fileRemark" slot-scope="text, obj">
						<a-input type="textarea" v-model="obj.note" :rows="4" :maxLength="300" />
					</span>
					<span slot="action" slot-scope="text, obj, index">
						<a @click="DelSup(index)">{{ $t('page.delete') }}</a>
					</span>
				</a-table>
			</a-descriptions-item>
			<a-descriptions-item>
				<UploadPreviewFile :uploadFilePath.sync="supplementFiles" :folderPath="$route.query.id" :isPreviewFile="false"></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<div class="btn_box">
			<a-button type="primary" @click="loanSave" v-t.preserve="'operate.save'"></a-button>
		</div>
		<a-modal v-drag-modal v-model="belongsModel.show" :title="belongsModel.title" :destroyOnClose="true" width="800px" @ok="handModalOk">
			<a-form-model :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
				<a-form-model-item label="PIN">
					<a-input v-model="belongsModel.pin" />
				</a-form-model-item>
				<a-form-model-item label="Name">
					<a-input v-model="belongsModel.name" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<a-modal
			v-drag-modal
			v-model="modal.show"
			:title="$t('table.useraccount.addfilemodal.title')"
			:destroyOnClose="true"
			width="600px"
			ok-text="ok"
			cancel-text="cancel"
			:confirm-loading="modal.loading"
			@ok="submitFile"
		>
			<a-form :form="modal" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
				<a-form-item :label="$t('table.useraccount.addfilemodal.bankName')">
					<a-select v-model="modal.bankname" style="width: 300px" @change="changeBankname">
						<a-select-option :value="item.codeValue" v-for="item in banklist" :key="item.id">
							{{ item.codeDescription }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item :label="$t('table.useraccount.addfilemodal.password')">
					<a-input v-model="modal.filePassword" />
				</a-form-item>
				<a-form-item :label="$t('table.useraccount.addfilemodal.file')">
					<a-button type="primary" class="file_btn">
						{{ $t('table.useraccount.addfilemodal.choosefile') }}
						<input type="file" ref="files_dom" class="input_file" @change="ChangeFile" />
					</a-button>
					<p>{{ modal.fileName }}</p>
				</a-form-item>
			</a-form>
		</a-modal>
		<!-- 流水弹窗 -->
		<a-modal v-drag-modal v-model="fullModal.show" title="Serial documents" :footer="false" width="90%" wrapClassName="full-modal">
			<a-tabs default-active-key="1">
				<a-tab-pane key="1" :tab="$t('table.useraccount.result.tab1')">
					<information :details.sync="details" v-if="fullModal.show"></information>
				</a-tab-pane>
				<a-tab-pane key="2" :tab="$t('table.useraccount.result.tab3')">
					<flowDetails :reqParam="fileNameList" :isFileResult="true" v-if="fullModal.show"></flowDetails>
				</a-tab-pane>
				<a-tab-pane key="3" :tab="$t('table.useraccount.result.tab2')" force-render>
					<trans :id="transcations" :isFileResult="true" v-if="fullModal.show"></trans>
				</a-tab-pane>
			</a-tabs>
		</a-modal>
	</div>
</template>
<script>
import moment from 'moment'
import { _axios } from '@/plugins/axios'
import loanForm from '@/views/emergencyLoan/components/loanForm.vue'
import information from '@/views/review/components/information'
import flowDetails from '@/views/review/components/flowDetails'
import trans from '@/views/review/components/transactions'
import { apiEmergencyApply, apiEmergencyDetail } from '@/api/emergencyLoan'
import { apiGetValuation, apiLoanDetail, apiGetFileStatus } from '@/api/loan'
import { apiGetCodeByName } from '@/api/code'
export default {
	data() {
		return {
			dateFormat: 'YYYY-MM-DD HH:mm:ss',
			loanInfo: {},
			belongsModel: {
				title: '',
				show: false,
				pin: '',
				name: ''
			},
			logbook: {
				id: '',
				logbookFile: '',
				make: '',
				model: '',
				year: '',
				registrationNumber: '',
				frameNumber: '',
				engineNo: '',
				vehicleUse: '',
				belongs: [
					{
						pin: '',
						name: ''
					}
				]
			},
			estimateBookFileTxtBool: false,
			valuation: {
				id: '',
				file: '',
				make: '',
				model: '',
				year: '',
				registrationNumber: '',
				frameNumber: '',
				engineNo: '',
				valuation: 0,
				saleValuation: 0,
				reportTime: ''
			},
			isAllRisks: 1,
			expireTime: '',
			insurancePic: '',
			transcations: [],
			applicationFormFile: '',
			banklist: [],
			modal: {
				loading: false,
				show: false,
				filePassword: '',
				bankname: '',
				fileName: '',
				files: {}
			},

			fullModal: {
				show: false
			},
			columns: [
				{
					// 流水机构
					title: this.$t('table.applyInfo.financialLabel.bankType'),
					dataIndex: 'institutionName',
					align: 'center'
				},
				{
					// 文件名称
					title: this.$t('table.applyInfo.financialLabel.fileName'),
					scopedSlots: { customRender: 'file' },
					// dataIndex: 'file',
					align: 'center'
				},
				{
					// 文件密码
					title: this.$t('table.applyInfo.financialLabel.filePassword'),
					dataIndex: 'filePassword',
					align: 'center'
				},
				{
					// 状态
					title: this.$t('table.loanInfo.caseInfo.status'),
					dataIndex: 'status',
					align: 'center',
					customRender: (v, o) => (v ? this.$t(`table.useraccount.file_status_map.${v}`) : '-')
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}
			],
			supplementFiles: []
		}
	},
	components: { loanForm, information, flowDetails, trans },
	computed: {
		supCloums() {
			return [
				{
					// 文件名称
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileName'),
					scopedSlots: { customRender: 'fileName' },
					align: 'center',
					width: 300
				},
				{
					// 文件
					title: this.$t('table.applyInfo.applicationLabel.supCloums.file'),
					scopedSlots: { customRender: 'file' },
					align: 'center'
				},
				{
					// 文件备注
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileRemark'),
					scopedSlots: { customRender: 'fileRemark' },
					align: 'center'
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 100
				}
			]
		},
		fileNameList() {
			let fileNameList = []
			this.transcations.map((item) => {
				fileNameList.push(item.file)
			})
			return fileNameList
		},
		vehicleData() {
			const json = require('@/utils/car.json')
			let newkey = Object.keys(json).sort()
			let vehicleData = {} //创建一个新的对象，用于存放排好序的键值对
			for (var i = 0; i < newkey.length; i++) {
				vehicleData[newkey[i]] = json[newkey[i]]
			}
			return vehicleData
		},
		vehicleMakeData() {
			return Object.keys(this.vehicleData)
		},
		vehicleModelData() {
			let vehicleModelData = []
			if (!this.$Util.isEmpty(this.logbook.make) && this.vehicleData[this.logbook.make]) {
				vehicleModelData = this.vehicleData[this.logbook.make].map((item) => {
					return item.title
				})
				vehicleModelData.push('Other')
			}
			return vehicleModelData
		},
		vehicleValuationModelData() {
			let vehicleModelData = []
			if (!this.$Util.isEmpty(this.valuation.make) && this.vehicleData[this.valuation.make]) {
				vehicleModelData = this.vehicleData[this.valuation.make].map((item) => {
					return item.title
				})
				vehicleModelData.push('Other')
			}
			return vehicleModelData
		}
	},
	created() {
		this.$nextTick(() => {
			apiGetCodeByName({ name: 'BANK_NAME' }).then((res) => {
				this.banklist = res.values
				this.banklist.unshift({
					codeDescription: 'Mpesa',
					codeValue: 'MPESA'
				})
			})
			apiEmergencyDetail(
				{
					id: this.$route.query.id
				},
				false
			).then((res) => {
				if (res.emergencyFormInfo && res.processStatus && res.processStatus.statusCode !== 'disburse_success') {
					this.loanInfo = res.emergencyFormInfo.loanInfo
					this.logbook = res.emergencyFormInfo.logbook
					this.valuation = res.emergencyFormInfo.valuation
					this.transcations = res.emergencyFormInfo.transactions ? res.emergencyFormInfo.transactions : []
					this.isAllRisks = res.emergencyFormInfo.isAllRisks
					this.expireTime = res.emergencyFormInfo.expireTime
					this.insurancePic = res.emergencyFormInfo.insurancePic
					this.applicationFormFile = res.emergencyFormInfo.applicationFormFile
					this.supplementFiles = res.emergencyFormInfo.supplementFiles ? res.emergencyFormInfo.supplementFiles : []
					if (this.valuation.file) {
						this.estimateBookFileTxtBool = true
					}
				}
			})
		})
	},
	methods: {
		moment: moment,
		setDataChange(params) {
			const key = params.key.split('.')
			key.length > 1 ? (this[key[0]][key[1]] = params.value) : (this[params.key] = params.value)
		},
		handModalOk() {
			const pinRege = /^[A-Za-z0-9]{11}$/
			if (!pinRege.test(this.belongsModel.pin)) {
				this.$message.error('pin error')
				return false
			} else if (!this.belongsModel.name) {
				this.$message.error('name error')
				return false
			}
			this.logbook.belongs.push({
				pin: this.belongsModel.pin,
				name: this.belongsModel.name
			})
			this.belongsModel.show = false
			this.belongsModel.pin = ''
			this.belongsModel.name = ''
		},
		AddBelongs() {
			this.belongsModel.title = this.$t('page.add')
			this.belongsModel.pin = ''
			this.belongsModel.name = ''
			this.belongsModel.show = true
		},
		DelBelongs(idx) {
			this.logbook.belongs.splice(idx, 1)
		},
		getValuation(type) {
			apiGetValuation({ filePath: type })
				.then((res) => {
					this.valuation = Object.assign(this.valuation, res)
					this.estimateBookFileTxtBool = true
				})
				.catch(() => {
					this.estimateBookFileTxtBool = false
					this.valuation = Object.assign(this.valuation, {
						engineNo: '',
						frameNumber: '',
						valuation: '',
						year: '',
						registrationNumber: '',
						model: '',
						make: '',
						saleValuation: '',
						reportTime: ''
					})
				})
		},
		ShowModal() {
			this.modal.show = true
			this.modal.fileName = ''
			this.modal.filePassword = ''
		},
		// 添加文件
		submitFile() {
			if (this.modal.bankname == '') {
				this.$message.error(this.$t('table.userFrom.warnsTxt4'))
				return false
			}
			// if (this.modal.bankname === 'MPESA' && !this.personalInfo.basicIdentityCard) {
			// 	this.$message.error('Please fill in the user’s ID number in the personal information')
			// 	return false
			// }
			if (this.modal.fileName == '') {
				this.$message.error(this.$t('table.userFrom.warnsTxt3'))
				return false
			}
			const name = new Date().getTime() + '.' + this.$refs.files_dom.files[0].name
			const fromData = new FormData()
			this.$set(this.modal.files, name, this.$refs.files_dom.files[0])
			console.log(this.modal.files)
			for (let key in this.modal.files) {
				fromData.append('files', this.modal.files[key], key)
			}
			fromData.append(
				'passwordInfo',
				JSON.stringify({
					[name]: this.modal.filePassword
				})
			)
			fromData.append(
				'bankType',
				JSON.stringify({
					[name]: this.modal.bankname
				})
			)
			this.$store.commit('pageState/setUploadState', true)
			this.modal.loading = true
			_axios
				.post('/car/other/loanAddFile', fromData, {
					headers: { 'Content-Type': 'multipart/form-data' }
				})
				.then(() => {
					this.$message.success('success')
					this.transcations.unshift({
						id: '',
						file: name,
						filePassword: this.modal.filePassword,
						institution: this.modal.bankname,
						institutionName: this.banklist.find((item) => this.modal.bankname === item.codeValue).codeDescription,
						status: '0'
					})
				})
				.finally(() => {
					this.modal.loading = false
					this.modal.show = false
					this.modal.files = {}
					this.$store.commit('pageState/setUploadState', false)
				})
		},
		changeBankname(val) {
			// if (this.personalInfo.basicIdentityCard && val === 'MPESA') {
			// 	this.modal.filePassword = this.personalInfo.basicIdentityCard
			// } else {
			// 	this.modal.filePassword = ''
			// }
		},
		CheckLoanDetail() {
			let fileNames = []
			this.transcations.map((item) => {
				fileNames.push(item.file)
			})
			let fileNamesStr = ''
			fileNames.forEach((element) => {
				fileNamesStr += 'fileNames=' + element + '&'
			})
			apiGetFileStatus(fileNamesStr).then((res) => {
				if (res) {
					this.transcations.forEach((element) => {
						element.status = res[element.file]
					})
				}
			})
			let params = {
				fileNames: '',
				date: this.moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
			}
			params.fileNames = fileNames.join(',')
			apiLoanDetail(params).then((res) => {
				this.details = res
				if (this.details) {
					this.fullModal.show = true
				}
			})
		},
		openUrl(val) {
			_axios
				.get(`/car/file/download/${val.file}`, { responseType: 'arraybuffer' })
				.then((res) => {
					window.open((window.URL || window.webkitURL).createObjectURL(new Blob([res], { type: 'application/pdf' })), '_blank')
				})
				.catch((err) => {
					console.log(err)
				})
		},
		ChangeFile() {
			if (this.$refs.files_dom.files.length > 0) {
				this.modal.fileName = this.$refs.files_dom.files[0].name
			}
		},
		delFile(index) {
			this.transcations.splice(index, 1)
		},
		closeTab(targetKey) {
			this.$store.commit('tabs/delTab', targetKey)
			this.$store.commit('tabs/setActiveTab', this.$route.query.from)
		},
		loanSave() {
			this.$store.commit('pageState/setUploadState', true)
			let params = {
				loanId: this.$route.query.id,
				loanInfo: this.loanInfo,
				logbook: this.logbook,
				valuation: this.valuation,
				isAllRisks: this.isAllRisks,
				expireTime: this.expireTime,
				insurancePic: this.insurancePic,
				transactions: this.transcations,
				applicationFormFile: this.applicationFormFile,
				supplementFiles: this.supplementFiles
			}
			apiEmergencyApply(params)
				.then(() => {
					this.$message.success('success')
					this.closeTab(this.$route.fullPath)
					this.$router.push(this.$route.query.from)
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => {
					this.$store.commit('pageState/setUploadState', false)
				})
		},
		// 补充文件删除
		DelSup(idx) {
			this.supplementFiles.splice(idx, 1)
		}
	}
}
</script>
<style scoped lang="less">
/deep/ .loan-info-form {
	.ant-input-number-handler-wrap {
		display: none !important;
	}
}
.ant-row.ant-form-item {
	margin-bottom: 10px;
}
.ant-input,
.ant-input-number,
.ant-select {
	width: 200px;
}
.file {
	left: 0;
	top: 0;
	width: 100%;
	bottom: 0;
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.file:hover {
	opacity: 0;
	text-decoration: none;
}
.input_box {
	width: 200px;
	vertical-align: top;
	display: inline-block;
	margin-right: 20px;
}
.input_file {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0;
	left: 0;
	top: 0;
}
.full-modal {
	.ant-modal {
		max-width: 100%;
		top: 0;
		padding-bottom: 0;
		margin: 0;
	}
	.ant-modal-content {
		display: flex;
		flex-direction: column;
		height: calc(100vh);
	}
	.ant-modal-body {
		flex: 1;
	}
}
</style>
